<script>
import partnerChoices from '@/mixins/partnerChoices';
import YesNoChoice from '../Form/YesNoChoice.vue';
import DateInput from '../Form/DateInput.vue';
import ConditionalGroup from '../Form/ConditionalGroup.vue';
import dayOptions from '@/lib/dayOptions';
import monthOptions from '@/lib/monthOptions';
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';

export default {
	components: { YesNoChoice, DateInput, ConditionalGroup },
	mixins: [partnerChoices, dayOptions],
	props: ['agreementData','hideForEdit','itemIndexToShow'],
	data() {
		return { dayOptions, monthOptions,
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		familyResidence() {
			return this.agreementData.familyResidence
			//  = this.agreementData.familyResidence.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.familyResidence 
			// 	: {...this.agreementData.familyResidence, additionalClausesToRecitals: []};
		},
		partnerOwnsChoices() {
			return this.partnerChoices.map((partner) => ({
				...partner,
				text: `${partner.text} owns the property`,
			}));
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		}
	},
	methods: {
		newLiability() {
			return {
				exclusions: [],
			};
		},
	},
	mounted(){
		this.agreementData.familyResidence = this.agreementData.familyResidence.hasOwnProperty('additionalClausesToRecitals') 
			? this.agreementData.familyResidence 
			: {...this.agreementData.familyResidence, additionalClausesToRecitals: []};
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Family Residence</h4>

		<address-input-group v-model="familyResidence.address" class="my-3" :testid="'residence-address'" :disabled="isViewMode"/>

		<b-form-group
			id="input-ownership"
			label="How is the residence owned?"
			label-for="ownership"
		>
			<b-form-select
				id="ownership"
				v-model="familyResidence.owned"
				:options="[
					{ text: 'Jointly', value: 'jointly' },
					...partnerOwnsChoices,
				]"
				data-testid="residence-ownership"
				class="col-lg-8"
				:disabled="isViewMode"
				:class="{ 'custom-disabled': isViewMode }"
			/>
		</b-form-group>

		<input-group
			v-model="familyResidence.value"
			reset-undefined
			class="my-3"
			dollar
			label="What is the value of the family residence?"
			:testid="'residence-value'"
			:disabled="isViewMode"
		/>
		<template v-if="!clientUserAgreement">
		<b-form-group
			id="input-appraisal"
			label="Was the value determined through an appraisal?"
			label-for="appraisal"
		>
			<b-form-select
				id="appraisal"
				v-model="familyResidence.appraisal"
				:options="[
					{ text: 'Yes', value: true },
					{
						text: 'No, the parties agreed to the value',
						value: false,
					},
				]"
				data-testid="residence-appraisal"
				class="col-lg-8"
				:disabled="isViewMode"
				:class="{ 'custom-disabled': isViewMode }"
			/>
		</b-form-group>

		<!-- Todo make this conditional work -->
		<conditional-group
			class="my-3"
			:condition="familyResidence.appraisal == true"
		>
			<date-input
				v-model="familyResidence.appraisalDate"
				label="Date of Appraisal"
				class="mb-3"
				:testid="'residence-appraisal-date'"
				:disabled="isViewMode"
			/>
		</conditional-group>
		</template>
		<p class="mt-3">
			Are there any liabilities registered on title to the family
			residence? If so, add details of each individual liability below:
		</p>
		<list-item
			v-slot="{ item: liability, index: index }"
			v-model="familyResidence.liabilities"
			:object-prototype="newLiability"
			new-label="Add Liability"
			:testid="'family-residence-'"
			:disabled="isViewMode"
		>
			<input-group
				v-model="liability.lender"
				input-cols="8"
				label="Name of Financial Institution or Lender"
				class="mb-3"
				:testid="'residence-institution-name-'+index"
				:disabled="isViewMode"
				
			/>

			<b-form-group
				id="input-registered"
				label="Liability registered on the property"
				:label-for="'registered-'+ index"
			>
				<b-form-select
					:id="'registered-'+ index"
					v-model="liability.registered"
					size="sm"
					:options="[
						{ text: 'Mortgage', value: 'Mortgage' },
						{ text: 'Line of Credit', value: 'Line of Credit' }, // value fix
						{ text: 'Other', value: 'other' },
					]"
					:data-testid="'residence-registered-'+index"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="liability.registered === 'other'"
			>
				<input-group
					v-model="liability.type"
					class="my-3"
					reset-undefined
					input-cols="8"
					label="Type of liability"
					:testid="'residence-type-'+index"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<input-group
				v-model="liability.lastFourDigits"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Last four digits of the account"
				placeholder="Digits (Optional)"
				:testid="'residence-last-four-digits-'+index"
				:disabled="isViewMode"
			/>

			<b-form-group
				id="input-responsible"
				label="Which party is responsible for the liability?"
				:label-for="'responsible-'+ index"
			>
				<b-form-select
					:id="'responsible-'+ index"
					v-model="liability.responsible"
					size="sm"
					:options="[
						{
							text: 'Parties are jointly responsible',
							value: 'jointly',
						},
						...partnerChoices,
					]"
					:data-testid="'residence-responsible-'+index"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<input-group
				v-model="liability.balance"
				reset-undefined
				class="my-3"
				dollar
				label="What is the amount owing?"
				:testid="'residence-owing-amount-'+index"
				:disabled="isViewMode"
			/>
		</list-item>
		<template v-if="!clientUserAgreement">
		<yes-no-choice
			v-if="!clientUserAgreement"
			v-model="familyResidence.hasExclusions"
			label="Are there any exclusions related to the Family Residence?"
			reset-undefined
			class="my-3"
			:testid="'residence-has-exclusions'"
			:disabled="isViewMode"
		/>

		<conditional-group
			class="my-3"
			:condition="familyResidence.hasExclusions"
		>
			<exclusion-table
				v-model="familyResidence.exclusions"
				:agreement-data="agreementData"
				:testid="'residence-exclusion-'"
				:disabled="isViewMode"
			/>
		</conditional-group>

		<b-form-group
			v-if="!clientUserAgreement"
			id="input-residenceOption"
			label="What will the parties do with the Family Residence?"
			label-for="residenceOption"
		>
			<b-form-select
				id="residenceOption"
				v-model="familyResidence.residenceOption"
				size="sm"
				:options="[
					{
						text: 'One party will keep the residence',
						value: 'keep',
					},
					{
						text: 'The parties will sell the residence and split the proceeds',
						value: 'split',
					},
					{
						text: 'The parties will continue to co-own the residence for a period of time',
						value: 'co-own',
					},
				]"
				data-testid="residence-parties-option"
				class="col-lg-8"
				:disabled="isViewMode"
				:class="{ 'custom-disabled': isViewMode }"
			/>
		</b-form-group>

		<conditional-group
			class="my-3"
			:condition="familyResidence.residenceOption === 'keep'"
		>
			<b-form-group
				id="input-keep"
				label="Which party will keep the family residence?"
				label-for="keep"
			>
				<b-form-select
					id="keep"
					v-model="familyResidence.keep"
					size="sm"
					reset-undefined
					:options="[...partnerChoices]"
					data-testid='residence-keep'
					class="col-lg-8"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<yes-no-choice
				v-model="familyResidence.stayingInResidence"
				reset-undefined
				label="Will the other party be staying in the family residence while they find alternative accommodation?"
				class="my-3"
				:testid="'residence-staying-in-residence'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="familyResidence.stayingInResidence"
			>
				<b-form-group
					id="input-contribute"
					label="During this time, will they contribute financially?"
					label-for="contribute"
				>
					<b-form-select
						id="contribute"
						v-model="familyResidence.contribute"
						size="sm"
						reset-undefined
						:options="[
							{
								text: 'Yes, they will contribute rent',
								value: 'contribute-rent',
							},
							{
								text: 'Yes, the parties will share costs equally',
								value: 'equally',
							},
							{
								text: 'Yes, the parties will share costs as agreed',
								value: 'as-agreed',
							},
							{
								text: 'Yes, the parties will share costs based on an agreed-upon schedule',
								value: 'schedule',
							},
							{
								text: 'No, they will not contribute financially',
								value: 'no',
							},
						]"
						:data-testid="'residence-financial-contribution-'"
						:disabled="isViewMode"
						:class="{ 'custom-disabled': isViewMode }"
					/>
				</b-form-group>

				<conditional-group
					class="my-3"
					:condition="
						familyResidence.contribute === 'contribute-rent'
					"
				>
					<input-group
						v-model="familyResidence.rentAmount"
						reset-undefined
						class="my-3"
						dollar
						label="Rent Amount"
						:testid="'residence-rent-amount-'"
						:disabled="isViewMode"
					/>

					<b-form-group
						id="input-rentCollection"
						label="When does Rent collection start?"
						label-for="rentCollectionStart"
					>
						<b-form-select
							id="rentCollectionStart"
							v-model="familyResidence.rentCollectionStart"
							size="sm"
							reset-undefined
							:options="[
								{
									text: 'On the first day of the month immediately following this Agreement being signed',
									value: 'firstDayAfterSigned',
								},
								{
									text: 'On the first day of the month immediately following the transfer of the Family Residence',
									value: 'firstDayAfterTransfer',
								},
								{
									text: 'On a particular date',
									value: 'date',
								},
							]"
							:data-testid="'residence-rent-collection-start'"
							:disabled="isViewMode"
							:class="{ 'custom-disabled': isViewMode }"
						/>
					</b-form-group>

					<conditional-group
						class="my-3"
						:condition="
							familyResidence.rentCollectionStart === 'date'
						"
					>
						<b-row>
							<b-col cols="4">
								<radio-group
									v-model="
										familyResidence.rentCollectionStartMonth
									"
									select
									label="Month"
									:options="monthOptions"
									:testid="'residence-rent-collection-start-month'"
									:length="'long'"
									:disabled="isViewMode"
								/>
							</b-col>
							<b-col cols="3">
								<radio-group
									v-model="
										familyResidence.rentCollectionStartDay
									"
									select
									label="Day"
									:options="dayOptions"
									:testid="'residence-rent-collection-start-day'"
									:disabled="isViewMode"
								/>
							</b-col>
						</b-row>
					</conditional-group>
					<check-group
						v-model="familyResidence.rentIncludes"
						other
						label="What does Rent include?"
						class="mt-3"
						:options="[
							'room and board',
							'utilities',
							'telephone',
							'cable',
							'internet',
							'groceries',
						]"
						:testid="'residence-rent-includes'"
						:id="'residence-rent-includes'"
						:disabled="isViewMode"
					/>

					<!-- <conditional-group
						class="my-3"
						:condition="
							familyResidence.rentIncludes &&
							familyResidence.rentIncludes.includes('other')
						"
					>
						<input-group
							input-cols="8"
							label="Add another item"
							class="mb-3"
							v-model="familyResidence.rentIncludesOther"
						/>
					</conditional-group> -->
				</conditional-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.stayingInResidence === false"
			>
				<yes-no-choice
					v-model="familyResidence.responsibleForExpenses"
					reset-undefined
					label="Will the party living in the house be solely responsible for the expenses?"
					class="my-3"
					:testid="'residence-expenses-responsibility'"
					:disabled="isViewMode"
				/>
				<conditional-group
					class="my-3"
					:condition="
						familyResidence.responsibleForExpenses === false
					"
				>
					<b-form-group
						id="input-manageExpenses"
						label="How will the parties manage the Family Residence expenses until it is transferred or sold?"
						label-for="manageExpenses"
					>
						<b-form-select
							id="manageExpenses"
							v-model="familyResidence.manageExpenses"
							size="sm"
							reset-undefined
							:options="[
								{
									text: 'They will share costs equally',
									value: 'equally',
								},
								{
									text: 'They will share costs as agreed',
									value: 'as-agreed',
								},
								{
									text: 'They will share costs based on an agreed-upon schedule',
									value: 'schedule',
								},
							]"
							data-testid='residence-expense-management'
							:disabled="isViewMode"
							:class="{ 'custom-disabled': isViewMode }"
						/>
					</b-form-group>
				</conditional-group>
			</conditional-group>
		</conditional-group>

		<conditional-group
			class="my-3"
			:condition="familyResidence.residenceOption === 'split'"
		>
			<b-form-group
				id="input-propertyState"
				label="What is the current state of the property?"
				label-for="propertyState"
			>
				<b-form-select
					id="propertyState"
					v-model="familyResidence.propertyState"
					size="sm"
					:options="[
						{
							text: 'To be listed for sale',
							value: 'to-be-listed',
						},
						{
							text: 'Listed for sale',
							value: 'listed',
						},
						{
							text: 'Contract for purchase and sale has been executed',
							value: 'contract-executed',
						},
					]"
					data-testid='residence-current-state'
					class="col-lg-8"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.propertyState === 'to-be-listed'"
			>
				<yes-no-choice
					v-model="familyResidence.upgradesBeforeSale"
					reset-undefined
					class="my-3"
					label="Will the parties make any upgrades to the property before they list it for sale?"
					:testid="'residence-upgrades-before-sale'"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="familyResidence.upgradesBeforeSale"
				>
					<b-form-group
						id="input-upgradesBeforeSale"
						label="How will the parties divide the cost of the upgrades?"
						label-for="upgradesBeforeSale"
					>
						<b-form-select
							id="upgradesBeforeSale"
							v-model="familyResidence.upgradesCostDivision"
							size="sm"
							:options="[
								{
									text: 'They will share costs equally',
									value: 'equally',
								},
								{
									text: 'They will share costs as agreed',
									value: 'as-agreed',
								}
							]"
							data-testid='residence-upgrades-cost-division'
							class="col-lg-8"
							:disabled="isViewMode"
							:class="{ 'custom-disabled': isViewMode }"
						/>
					</b-form-group>
				</conditional-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.propertyState === 'listed'"
			>
				<input-group
					v-model="familyResidence.realEstateAgent"
					input-cols="8"
					label="Name of real estate agent"
					class="mb-3"
					:testid="'residence-real-estate-agent-name'"
					:disabled="isViewMode"
				/>

				<input-group
					v-model="familyResidence.listingPrice"
					label="Listing Price"
					class="mb-3"
					dollar
					:testid="'residence-listing-price'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="
					familyResidence.propertyState === 'contract-executed'
				"
			>
				<input-group
					v-model="familyResidence.salePrice"
					label="What was the sale price of the family residence?"
					class="mb-3"
					dollar
					:testid="'residence-sale-price'"
					:disabled="isViewMode"
				/>

				<date-input
					v-model="familyResidence.saleClosingDate"
					label="What is the closing date of the sale?"
					class="mb-3"
					:testid="'residence-sale-closing-date'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<b-form-group
				id="input-liveInUntilSold"
				label="Who will live in the house until it is sold?"
				label-for="liveInUntilSold"
			>
				<b-form-select
					id="liveInUntilSold"
					v-model="familyResidence.liveInUntilSold"
					size="sm"
					:options="[
						...partnerChoices,
						{ text: 'Both', value: 'both' },
					]"
					data-testid='residence-live-in-until-sold'
					class="col-lg-8"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.liveInUntilSold === 'both'"
			>
				<b-form-group
					id="input-manageExpenses"
					label="How will the parties manage the Family Residence expenses until it is sold?"
					label-for="manageExpenses"
				>
					<b-form-select
						id="manageExpenses"
						v-model="familyResidence.manageExpenses"
						size="sm"
						:options="[
							{
								text: 'They will share costs equally',
								value: 'equally',
							},
							{
								text: 'They will share costs as agreed',
								value: 'as-agreed',
							},
							{
								text: 'They will share costs based on an agreed-upon schedule',
								value: 'schedule',
							},
						]"
						data-testid='residence-expense-management-until-sold'
						class="col-lg-8"
						:disabled="isViewMode"
						:class="{ 'custom-disabled': isViewMode }"
					/>
				</b-form-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.liveInUntilSold !== 'both'"
			>
				<yes-no-choice
					v-model="familyResidence.responsibleForExpenses"
					reset-undefined
					class="my-3"
					label="Will the party living in the house be solely responsible for the expenses?"
					:testid="'residence-expense-responsibility'"
					:disabled="isViewMode"
				/>
				<conditional-group
					class="my-3"
					:condition="
						familyResidence.responsibleForExpenses === false
					"
				>
					<b-form-group
						id="input-manageExpenses"
						label="How will the parties manage the Family Residence expenses until it is sold?"
						label-for="manageExpenses"
					>
						<b-form-select
							id="manageExpenses"
							v-model="familyResidence.manageExpenses"
							size="sm"
							:options="[
								{
									text: 'They will share costs equally',
									value: 'equally',
								},
								{
									text: 'They will share costs as agreed',
									value: 'as-agreed',
								},
								{
									text: 'They will share costs based on an agreed-upon schedule',
									value: 'schedule',
								},
							]"
							data-testid='residence-parties-expense-management-until-sold'
							class="col-lg-8"
							:disabled="isViewMode"
							:class="{ 'custom-disabled': isViewMode }"
						/>
					</b-form-group>
				</conditional-group>
			</conditional-group>
		</conditional-group>

		<conditional-group
			class="my-3"
			:condition="familyResidence.residenceOption === 'co-own'"
		>
			<b-form-group
				id="input-coOwnDecision"
				label="The parties will co-own until they purchase or sell, as decided on:"
				label-for="coOwnDecision"
			>
				<b-form-select
					id="coOwnDecision"
					v-model="familyResidence.coOwnDecision"
					size="sm"
					:options="[
						{ text: 'A specific date', value: 'specific-date' },
						{
							text: 'On a date as agreed upon between the parties',
							value: 'arranged-date',
						},
					]"
					data-testid='residence-co-own-decision'
					class="col-lg-8"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.coOwnDecision === 'specific-date'"
			>
				<date-input
					v-model="familyResidence.coOwnDecisionDate"
					label="Specified Date"
					class="mb-3"
					:testid="'residence-co-own-decision-date'"
					:disabled="isViewMode"
				/>
			</conditional-group>

			<yes-no-choice
				v-model="familyResidence.purchaseFamilyResidence"
				label="Would either party like the first option to purchase the other party's interest in the Family Residence?"
				reset-undefined
				class="my-3"
				:testid="'residence-other-party-purchase'"
				:disabled="isViewMode"
			/>

			<conditional-group
				class="my-3"
				:condition="familyResidence.purchaseFamilyResidence"
			>
				<b-form-group
					id="input-whoCanPurchase"
					label="Which party will be given the first opportunity to exercise the option to purchase?"
					label-for="whoCanPurchase"
				>
					<b-form-select
						id="whoCanPurchase"
						v-model="familyResidence.whoCanPurchase"
						size="sm"
						:options="[...partnerChoices]"
						reset-undefined
						data-testid='residence-who-can-purchase'
						class="col-lg-8"
						:disabled="isViewMode"
						:class="{ 'custom-disabled': isViewMode }"
					/>
				</b-form-group>
			</conditional-group>

			<b-form-group
				id="input-whoWillLiveInResidence"
				label="Who will live in the house until it is sold?"
				label-for="whoWillLiveInResidence"
			>
				<b-form-select
					id="whoWillLiveInResidence"
					v-model="familyResidence.whoWillLiveInResidence"
					size="sm"
					:options="[
						...partnerChoices,
						{ text: 'Both', value: 'both' },
					]"
					data-testid='residence-who-will-live-in'
					class="col-lg-8"
					:disabled="isViewMode"
					:class="{ 'custom-disabled': isViewMode }"
				/>
			</b-form-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.whoWillLiveInResidence !== 'both'"
			>
				<yes-no-choice
					v-model="
						familyResidence.personLivingInResidenceResponsibleForExpenses
					"
					label="Will this party be solely responsible for the expenses?"
					reset-undefined
					class="my-3"
					:testid="'residence-expense-responsibility'"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="
						!familyResidence.personLivingInResidenceResponsibleForExpenses
					"
				>
					<b-form-group
						id="input-manageExpenses"
						label="How will the parties manage the Family Residence expenses until it is sold?"
						label-for="manageExpenses"
					>
						<b-form-select
							id="manageExpenses"
							v-model="familyResidence.manageExpenses"
							size="sm"
							:options="[
								{
									text: 'They will share costs equally',
									value: 'equally',
								},
								{
									text: 'They will share costs as agreed',
									value: 'as-agreed',
								},
								{
									text: 'They will share costs based on an agreed-upon schedule',
									value: 'schedule',
								},
							]"
							data-testid='residence-expense-management-until-sold'
							class="col-lg-8"
							:disabled="isViewMode"
							:class="{ 'custom-disabled': isViewMode }"
						/>
					</b-form-group>
				</conditional-group>
			</conditional-group>

			<conditional-group
				class="my-3"
				:condition="familyResidence.whoWillLiveInResidence === 'both'"
			>
				<b-form-group
					id="input-managecolivingExpenses"
					label="How will the parties manage the Family Residence expenses until it is sold?"
					label-for="managecolivingExpenses"
				>
					<b-form-select
						id="managecolivingExpenses"
						v-model="familyResidence.managecolivingExpenses"
						size="sm"
						:options="[
							{
								text: 'They will share costs equally',
								value: 'equally',
							},
							{
								text: 'They will share costs as agreed',
								value: 'as-agreed',
							},
							{
								text: 'They will share costs based on an agreed-upon schedule',
								value: 'schedule',
							},
						]"
						data-testid='residence-manage-co-living-expenses'
						class="col-lg-8"
						:disabled="isViewMode"
						:class="{ 'custom-disabled': isViewMode }"
					/>
				</b-form-group>
			</conditional-group>
		</conditional-group>
		</template>
		<template v-if="!clientUserAgreement">
			<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-2" v-model="familyResidence.additionalClausesToRecitals" :testid="'residence-add-clause-to-recitals-'"
			:disabled="isViewMode"/>
			<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="familyResidence.additionalClauses" :testid="'residence-add-clause-'"
			:disabled="isViewMode"/>
		</template>
		</b-card>
</template>
